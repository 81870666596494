<template>
    <div>
        <v-card class="cardClass d-flex rounded-lg pa-2">
            <div class="dataRow d-flex">
                <div>
                    <p class="ma-1 dataFieldClass">Booking Id :</p>
                </div>
                <div>
                    <p class="ma-1 dataValueClass">{{ appointmentData.appointment_reference_number }}</p>
                </div>
            </div>
            <div class="dataRow d-flex">
                <div>
                    <p class="ma-1 dataFieldClass">Patient Name :</p>
                </div>
                <div>
                    <p class="ma-1 dataValueClass">{{ appointmentData.booked_for_name }}</p>
                </div>
            </div>
            <div class="dataRow d-flex">
                <div>
                    <p class="ma-1 dataFieldClass">Phone Number :</p>
                </div>
                <div>
                    <p class="ma-1 dataValueClass">{{ appointmentData.phoneNumber}}</p>
                </div>
            </div>
            <div class="dataRow d-flex">
                <div>
                    <p class="ma-1 dataFieldClass">Date Of Appointment :</p>
                </div>
                <div>
                    <p class="ma-1 dataValueClass">
                        <DateRendered :dateVal="appointmentData.date_of_appointment">
                        </DateRendered>
                    </p>
                </div>
            </div>
            <div class="dataRow d-flex">
                <div>
                    <p class="ma-1 dataFieldClass">Time Slot :</p>
                </div>
                <div>
                    <p class="ma-1 dataValueClass">
                        <TimeRender :timeSlot="appointmentData.time_slot"> </TimeRender>
                    </p>
                </div>
            </div>
            <div class="dataRow d-flex">
                <div>
                    <p class="ma-1 dataFieldClass">Prescription :</p>
                </div>
                <div v-if="appointmentData.prescription_reference_number != ''">
                    <div class="prescriptionViewbtnSubmited rounded-lg d-flex" @click="openPrescription(appointmentData)">
                        <span class="prescriptionStatusBtn"> Submitted</span>
                    </div>
                </div>
                <div v-else>
                    <div class="prescriptionViewbtnNotSubmited rounded-lg d-flex">
                        <span class="prescriptionStatusBtn">Not Submitted</span>
                    </div>
                </div>
            </div>
            <div>
                <v-btn
                class="primary mt-1 viewAppoitnmentbtn"
                @click="viewAppointment(appointmentData._id)"
                style="background-color: #1467BF;"
                >View Appointment</v-btn>
            </div>
        </v-card>
    </div>
</template>
<script>
    export default{
        name: 'AppointmentHistoryCard',
        data(){
            return{

            }
        },
        props:['appointmentData'],
        components: {
            DateRendered: () => import('../views/Pages/Customer/dateRender.vue'),
            TimeRender: () => import('../views/Pages/Customer/TimeRender.vue')
        },
        mounted(){},
        methods:{
            openPrescription(appointmentData){
                this.$emit("openPrescription", appointmentData.prescription_reference_number, appointmentData.appointment_reference_number,appointmentData.booked_for_name, appointmentData.customerId,appointmentData.specialization_name,appointmentData.date_of_appointment)
            },
            viewAppointment(bookingId) {
                this.$router.push({
                    path: "/doctor/viewappointment/" + bookingId,
                });
            },
        }
    }
</script>
<style scoped>
.cardClass{
    flex-direction: column;
}
.dataRow{
    justify-content: space-between;
    margin-bottom: 8px;
}
.dataFieldClass{
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    text-transform: capitalize;
    width: 100% !important;
}
.dataValueClass{
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
}
.prescriptionViewbtnNotSubmited{
    width: 150px;
    background: #EB5757;
    justify-content: center;
}
.prescriptionViewbtnSubmited{
    width: 150px;
    background: #88CE65;;
    justify-content: center;
}
.prescriptionStatusBtn{
    font-size: 14px;
    color: white;
    font-weight: 400;
    padding: 4px;
    line-height: 16px;
}
.viewAppoitnmentbtn{
    height: 30px !important;
    width: 90%;
}
</style>